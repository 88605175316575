import { useTranslation } from "@cx/react-polyglot";
import { ApplicationTransitionButton } from "@cx/ui-features";
import { FC } from "react";
import { BiUndo } from "react-icons/bi";
import { useApplicantActionContext } from "./ApplicantActionContext";

export const ResetButton: FC = () => {
  const { t } = useTranslation("applications");
  const { fontSize, updateStatus } = useApplicantActionContext();

  return (
    <ApplicationTransitionButton
      icon={BiUndo}
      label={t("undo")}
      fontSize={fontSize}
      onClick={() => updateStatus("RESET")}
      colorScheme="yellow"
    />
  );
};
