import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { JobTable } from "~/src/feature/Job/JobTable";

export const ListJobs: FC = () => {
  return (
    <>
      <PageTitle page="jobs" type="list" create />
      <JobTable />
    </>
  );
};
