import { IconProps } from "@chakra-ui/react";
import { ApplicationStatus } from "@cx/feathers-client";
import { TransitionJobApplication } from "@cx/ui-features";
import { createContext, useContext } from "react";

interface ApplicantActionContext extends Pick<IconProps, "fontSize"> {
  status: ApplicationStatus;
  updateStatus: TransitionJobApplication;
}

export const ApplicantActionContext = createContext(
  {} as ApplicantActionContext,
);

export function useApplicantActionContext() {
  const context = useContext(ApplicantActionContext);

  if (!context) {
    throw new Error(
      "ApplicantActionContext is undefined, please nest it in an ApplicantActionProvider",
    );
  }

  return context;
}
