import { Link } from "@chakra-ui/react";
import { taggedMessage, toDateString } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import { UnknownObject } from "@cx/types";
import { DateFilter } from "@cx/ui-common";
import {
  CellParams,
  DataTable,
  EmptyState,
  ResourceColumn,
  useDataTableHydrator,
} from "@cx/ui-data-table";
import {
  getRedactableName,
  JobLink,
  RedactableJobApplication,
} from "@cx/ui-features";
import { FC, useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useList } from "react-use";
import { ApplicantActions } from "./ApplicantActions/ApplicantActions";

type Props = {
  query?: UnknownObject;
};

type ActionParams = CellParams<
  Pick<RedactableJobApplication, "_id" | "status">
>;

const msg = taggedMessage("job-application-table");

export const JobApplicationTable: FC<Props> = ({ query = {} }) => {
  const resource = "job-applications";
  const { data, invalidate, ...hydratorProps } = useDataTableHydrator(
    resource,
    {
      $populate: ["job"],
      ...query,
    },
  );
  const { t } = useTranslation("applications");
  const [applications, applicationOps] = useList<RedactableJobApplication>([]);

  useEffect(() => {
    applicationOps.set(data as unknown as RedactableJobApplication[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    console.debug(
      msg`Data version changed %s, clearing applicants`,
      hydratorProps.version,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hydratorProps.version]);

  const columns = useMemo(
    (): ResourceColumn<RedactableJobApplication[]> => [
      {
        Header: t("job"),
        accessor: "job",
        Cell: JobLink,
        disableSortBy: true,
      },
      {
        Header: t("candidate"),
        id: "candidate",
        accessor({ _id, firstName, lastName }) {
          return { _id, firstName, lastName };
        },
        Cell({ value: { _id, firstName, lastName } }) {
          return (
            <Link as={RouterLink} to={`/job-applications/${_id}`}>
              {getRedactableName(firstName, lastName)}
            </Link>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t("createdAt"),
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return <>{toDateString(createdAt)}</>;
        },
      },
      {
        Header: "",
        id: "button",
        accessor({ _id, status }) {
          return { _id, status };
        },
        Cell({ value }: ActionParams) {
          return (
            <ApplicantActions
              {...value}
              onUpdate={(application) =>
                applicationOps.updateFirst(
                  (current, next) => current._id === next._id,
                  application,
                )
              }
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource="job applications" />}
        columns={columns}
        data={applications}
        {...hydratorProps}
        isSearchable
        searchPlaceholder={t("searchPlaceholder")}
        initialState={{
          sortBy: [{ id: "createdAt", desc: true }],
        }}
      >
        {(instance) => <DateFilter instance={instance} />}
      </DataTable>
    </>
  );
};
