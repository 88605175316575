import { actionMatcherFactory } from "@cx/action-matcher";
import { createSlice } from "@reduxjs/toolkit";
import { RecruiterWithCompany } from "./types";

export * from "./recruiter.selectors";
export * from "./recruiter.thunks";

export type RecruiterState = {
  value: RecruiterWithCompany;
  loading: boolean;
  error: boolean;
};

const slice = createSlice({
  name: "recruiter",
  initialState: {
    value: {},
  } as RecruiterState,
  reducers: {},
  extraReducers(builder) {
    const matcher = actionMatcherFactory(builder);

    matcher("recruiter", (state, payload?: RecruiterWithCompany) => {
      if (payload) {
        state.value = payload;
      }
    });
  },
});

export const recruiterReducer = slice.reducer;
