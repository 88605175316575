export default {
  htmlAttributes: { lang: "en" },
  title: "",
  titleTemplate: "%s | CX Recruiter",
  meta: [
    {
      name: "description",
      content:
        "CandidateX is a social impact and data-driven organisation, focused on increasing access to opportunities for underestimated talent.",
    },
  ],
};
