import { EntitySpinner } from "@cx/ui-common";
import { CompanyForm } from "@cx/ui-features";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "../components/PageTitle";
import { selectRecruiterCompany } from "../state/slices/recruiter-slice";

export const CompanyEdit: FC = () => {
  const company = useSelector(selectRecruiterCompany);
  const { data, isLoading, error } = useEntityHydrator(
    "companies",
    {},
    company._id,
  );

  if (isLoading) {
    return <EntitySpinner />;
  }

  if (error || !data) {
    return null;
  }

  return (
    <>
      <PageTitle type="edit" page="company" />
      <CompanyForm data={data} />
    </>
  );
};
