import { FC } from "react";
import { Helmet } from "react-helmet-async";

import { Box, Text } from "@chakra-ui/react";

type Props = {
  heading: string;
  pageTitle: string;
  subheading?: string;
};

export const HeaderInfo: FC<Props> = ({ heading, pageTitle, subheading }) => {
  return (
    <>
      <Helmet title={pageTitle} />
      <Box textAlign={["left", "center"]} w={["full", "auto"]} mb={["3", "6"]}>
        <Text variant="cx-title"> {heading}</Text>
        <Text variant="cx-subtitle">{subheading}</Text>
      </Box>
    </>
  );
};
