import { Button } from "@chakra-ui/react";
import { Recruiter } from "@cx/l10n";
import { useTranslation } from "@cx/react-polyglot";
import { PageTitle as BasePageTitle } from "@cx/ui-common";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

type Pages = keyof Recruiter["pageTitles"];
type PageTypes = "list" | "edit" | "create" | "view";

type PageTitleProps = {
  type: PageTypes;
  page: Pages;
  create?: boolean;
  label?: string;
  children?: ReactNode;
};

export const PageTitle: FC<PageTitleProps> = ({
  type,
  page,
  create = false,
  label = "",
  children,
}) => {
  const { t } = useTranslation("nav", `pageTitles.${page}`);
  const title = type === "list" ? t(page) : t(type, { label });

  return (
    <BasePageTitle title={title}>
      {children ??
        (create && (
          <Button as={Link} variant="outline" to="create">
            {t("create")}
          </Button>
        ))}
    </BasePageTitle>
  );
};
