import { useTranslation } from "@cx/react-polyglot";
import { SummaryCard } from "@cx/ui-common";
import { FC } from "react";
import { JobApplicationSummaryTable } from "../JobApplication";

export const JobApplicationSummary: FC = () => {
  const { t } = useTranslation("dashboard");

  return (
    <SummaryCard label={t("latest.applications")}>
      <JobApplicationSummaryTable />
    </SummaryCard>
  );
};
