import { JobWithCompanyAndOwner } from "@cx/feathers-client";
import { EntitySpinner } from "@cx/ui-common";
import { useEntityHydrator } from "@cx/ui-hooks";
import { FC } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "~/src/components/PageTitle";
import { RecruiterJobForm } from "~/src/feature/Job/RecruiterJobForm";
import { selectRecruiterCompany } from "~/src/state/slices/recruiter-slice";

export const EditJob: FC = () => {
  const company = useSelector(selectRecruiterCompany);
  const { data, isLoading } = useEntityHydrator("jobs", {
    $populate: ["company", "owner"],
  });

  if (isLoading || !data) {
    return <EntitySpinner />;
  }

  const job = data as unknown as JobWithCompanyAndOwner;

  return (
    <>
      <PageTitle page="jobs" type="edit" label={data.title} />
      <RecruiterJobForm job={job} company={company} />
    </>
  );
};
