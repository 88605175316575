import { SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { selectUser } from "@cx/ui-state";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { JobApplicationSummary, JobSummary } from "../feature/Dashboard";
import { Welcome, RecordCount } from "@cx/ui-common";

export const Dashboard: FC = () => {
  const { t } = useTranslation("dashboard", "nav");
  const user = useSelector(selectUser);

  const text = {
    error: t("error"),
    empty: t("empty"),
  };

  return (
    <>
      <Helmet title={t("dashboard")} />
      <Welcome
        salutation={t("salutation", {
          name: user.firstName,
        })}
        subHeading={t("subHeading")}
      />

      <SimpleGrid columns={[1, 3]} gap={["5", "6"]} mb="6">
        <RecordCount service="jobs" label={t("postedJobs")} text={text} />
        <RecordCount
          service="job-applications"
          label={t("applications")}
          text={text}
        />
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} gap={["5", "6"]}>
        <JobSummary />
        <JobApplicationSummary />
      </SimpleGrid>
    </>
  );
};
