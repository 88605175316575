import { Heading, Link, Text } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { EntitySpinner } from "@cx/ui-common";
import { RedactableJobApplication } from "@cx/ui-features";
import { useEntityHydrator } from "@cx/ui-hooks";
import { TabLinks } from "@cx/ui-tab-links";
import { FC, useEffect, useState } from "react";
import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { PageTitle } from "~/src/components/PageTitle";
import { ApplicantActions } from "./ApplicantActions/ApplicantActions";

type JobApplicationContext = {
  application: RedactableJobApplication;
};

export function useJobApplicationContext() {
  return useOutletContext<JobApplicationContext>();
}

export const JobApplicationViewLayout: FC = () => {
  const { data, isLoading } = useEntityHydrator("job-applications", {
    $populate: ["job"],
  });
  const { t } = useTranslation("applications");
  const [application, setApplication] = useState<RedactableJobApplication>();

  useEffect(() => {
    if (data) {
      setApplication(data as unknown as RedactableJobApplication);
    }
  }, [data]);

  if (isLoading || !application) {
    return <EntitySpinner />;
  }

  return (
    <>
      <PageTitle page="jobApplications" type="view">
        <ApplicantActions
          _id={application._id}
          status={application.status}
          fontSize="2xl"
          onUpdate={(application) => setApplication(application)}
        />
      </PageTitle>

      <Heading as="h2" size="md" mb="8">
        <Text as="span">{t("appliedFor")}</Text>{" "}
        <Link as={RouterLink}>{application.job.title}</Link>
      </Heading>

      {/* navigation */}
      <TabLinks
        items={[
          {
            name: t("summary"),
          },
          {
            name: t("resume"),
            path: "resume",
          },
        ]}
      />

      {/* children */}
      <Outlet context={{ application }} />
    </>
  );
};
