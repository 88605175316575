import { Phrases } from "@cx/react-polyglot";
import { FormProviderProps } from "@cx/ui-form";

export const formConfig: Omit<FormProviderProps<Phrases>, "children"> = {
  googlePlaces: {
    apiKey: globalThis.config.VITE_MAPS_API_KEY,
    autocompletionRequest: {
      types: ["(cities)"],
    },
    defaultError: "common.location.invalid",
  },
  dropdown: {
    placeholder: ["common.select", "common.selectAll"],
  },
  submitButton: {
    labels: {
      cancel: "common.cancel",
      save: "common.save",
    },
  },
};
