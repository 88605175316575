import { Box, Center, Spinner } from "@chakra-ui/react";
import {
  ApplicationTransitionButtonProps,
  RedactableJobApplication,
  useApplicationStatusTransition,
} from "@cx/ui-features";
import { FC, useEffect } from "react";
import { ApplicantActionButtons } from "./ApplicantActionButtons";
import { ApplicantActionContext } from "./ApplicantActionContext";

type Props = Pick<RedactableJobApplication, "_id" | "status"> & {
  fontSize?: ApplicationTransitionButtonProps["fontSize"];
  onUpdate?: (application: RedactableJobApplication) => void;
};

export const ApplicantActions: FC<Props> = ({
  _id,
  status: initialStatus,
  fontSize,
  onUpdate,
}) => {
  const { loading, error, updateStatus, status, application } =
    useApplicationStatusTransition(_id, initialStatus);

  useEffect(() => {
    if (onUpdate && application) {
      onUpdate(application);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  // handle error
  if (error) {
    // todo: toast error message
    console.error(error);
  }

  if (loading) {
    return (
      <Center p="1">
        <Spinner />
      </Center>
    );
  }

  return (
    <ApplicantActionContext.Provider
      value={{
        fontSize,
        status,
        updateStatus,
      }}
    >
      <ApplicantActionButtons />
    </ApplicantActionContext.Provider>
  );
};
