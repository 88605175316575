import { Route } from "react-router-dom";
import { AuthLayout } from "~/src/components/Layout";
import { ForgotPassword } from "./ForgotPassword";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import { SetPassword } from "./SetPassword";
import { VerifyEmail } from "./VerifyEmail";

export const AuthRoute = (
  <Route path="/">
    <Route index element={<Login />} />

    <Route path="verification" element={<AuthLayout />}>
      <Route path=":token" element={<VerifyEmail />} />
    </Route>

    <Route path="reset-password" element={<AuthLayout />}>
      <Route index element={<ForgotPassword />} />
      <Route path=":token" element={<ResetPassword />} />
    </Route>

    <Route path="set-password" element={<AuthLayout />}>
      <Route path=":token" element={<SetPassword />} />
    </Route>
  </Route>
);
