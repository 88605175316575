import { Link } from "@chakra-ui/react";
import { toDateString } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import {
  DataTable,
  EmptyState,
  ResourceColumn,
  useDataTableHydrator,
} from "@cx/ui-data-table";
import { FC, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

export const JobSummaryTable: FC = () => {
  const resource = "jobs";
  const { data, invalidate, ...hydratorProps } = useDataTableHydrator(
    resource,
    {
      $limit: 5,
    },
  );
  const { t } = useTranslation("jobs");

  const columns = useMemo(
    (): ResourceColumn<typeof data> => [
      {
        Header: t("title"),
        accessor({ _id, title }) {
          return (
            <Link as={RouterLink} to={`/jobs/${_id}`}>
              {title}
            </Link>
          );
        },
      },
      {
        Header: t("location"),
        accessor: "location.formatted",
      },
      {
        Header: t("createdAt"),
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return <>{toDateString(createdAt)}</>;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource={resource} />}
        columns={columns}
        data={data}
        initialState={{
          pageIndex: 0,
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        {...hydratorProps}
        isPaginated={false}
        showTotal={false}
      />
    </>
  );
};
