import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { DefaultLayout } from "./components/Layout";
import { NotFound } from "./pages/404";
import { AuthRoute } from "./pages/Auth/routes";
import { CompanyEdit } from "./pages/CompanyEdit";
import { Dashboard } from "./pages/Dashboard";
import { JobApplicationRoutes } from "./pages/JobApplications/routes";
import { JobRoutes } from "./pages/Jobs/routes";
import { Settings } from "./pages/Settings";

export const RoutesCollection: FC = () => {
  return (
    <Routes>
      {/* Auth*/}
      {AuthRoute}

      <Route path="/" element={<DefaultLayout />}>
        {/* Dashboard */}
        <Route path="dashboard" element={<Dashboard />} />

        {/* Jobs */}
        {JobRoutes}

        {/* Company */}
        <Route path="company" element={<CompanyEdit />} />

        {/* Settings */}
        <Route path="settings" element={<Settings />} />

        {/* Job Applications */}
        {JobApplicationRoutes}
      </Route>

      {/* Not Found */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
