import { Phrases, useTranslation } from "@cx/react-polyglot";
import { navbarItems } from "./navbar-items";

export function useNavbarItems() {
  const { t } = useTranslation();

  return navbarItems.map((item) => ({
    ...item,
    name: t(item.name as Phrases),
  }));
}
