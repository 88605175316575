import { useTranslation } from "@cx/react-polyglot";
import { ApplicationTransitionButton } from "@cx/ui-features";
import { FC } from "react";
import { BsEye } from "react-icons/bs";
import { useApplicantActionContext } from "./ApplicantActionContext";

export const RevealButton: FC = () => {
  const { t } = useTranslation("applications");
  const { fontSize, updateStatus } = useApplicantActionContext();

  return (
    <ApplicationTransitionButton
      icon={BsEye}
      label={t("reveal")}
      fontSize={fontSize}
      onClick={() => updateStatus("REVEAL")}
      colorScheme="green"
    />
  );
};
