import { Center, Container } from "@chakra-ui/react";
import { SimpleNavBar } from "@cx/ui-common";
import { ErrorBoundedOutlet } from "@cx/ui-features";
import { useOnClear } from "@cx/ui-hooks";
import { FC } from "react";

export const AuthLayout: FC = () => {
  useOnClear();

  return (
    <>
      <SimpleNavBar />
      <Center h="calc(100vh - 80px)">
        <Container size="xs">
          <ErrorBoundedOutlet />
        </Container>
      </Center>
    </>
  );
};
