import { opts, RootState } from "@cx/ui-state";
import { FeathersError } from "@feathersjs/errors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RecruiterWithCompany } from "./types";

// fetch recruiter
export const fetchRecruiter = createAsyncThunk<
  RecruiterWithCompany,
  void,
  { state: RootState }
>("recruiter/fetch", async (_, { rejectWithValue }) => {
  try {
    const recruiter = await opts
      .get("app")
      .service("recruiters")
      .get("me", { query: { $populate: "company" } });

    return recruiter as unknown as RecruiterWithCompany;
  } catch (error) {
    const { className, code, data, message, name } = error as FeathersError;
    return rejectWithValue({ className, code, data, message, name });
  }
});
