import { Box, Button, chakra } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { AuthEmailInput, AuthPasswordInput } from "@cx/ui-features";
import { FormInput, FormRow } from "@cx/ui-form";
import { useToastStatus } from "@cx/ui-hooks";
import { selectUser, updateUser, useAppDispatch } from "@cx/ui-state";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { UserSchema } from "./user-schema";

export const UserForm: FC = () => {
  const toast = useToastStatus();
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { t } = useTranslation("settings", "common");

  const methods = useForm<UserSchema>({
    resolver: yupResolver(UserSchema, { stripUnknown: true }),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    },
    mode: "all",
  });

  async function onSubmit({ ...payload }: UserSchema) {
    try {
      await dispatch(updateUser(payload)).unwrap();
      toast.success(t("success"));
    } catch (error) {
      console.error(error);
      toast.error((error as Error).message);
    }
  }

  return (
    <FormProvider {...methods}>
      <chakra.form
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete="false"
        noValidate
      >
        <FormRow>
          <FormInput
            title={t("firstName")}
            field="firstName"
            type="text"
            autoComplete="given-name"
            aria-label={t("firstName")}
          />
          <FormInput
            title={t("lastName")}
            field="lastName"
            type="text"
            autoComplete="family-name"
            aria-label={t("lastName")}
          />
        </FormRow>

        <FormRow>
          <AuthEmailInput />
          <AuthPasswordInput current={false} />
        </FormRow>

        {/* Buttons */}
        <Box mt="10" textAlign="right">
          <Button type="submit">{t("save")}</Button>
        </Box>
      </chakra.form>
    </FormProvider>
  );
};
