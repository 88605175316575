import { Box, Flex, Stack } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import {
  ResumeThumbnail,
  ResumeViewer,
  ResumeViewerProvider,
} from "@cx/ui-features";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { useJobApplicationContext } from "~/src/feature/JobApplication";

export const ViewJobApplicationResume: FC = () => {
  const {
    application: { _id, resume, candidate },
  } = useJobApplicationContext();

  const { t } = useTranslation("applications");

  return (
    <ResumeViewerProvider
      adobeId={globalThis.config.VITE_ADOBE_ID}
      initial={resume.redacted?.key ? "redacted" : "original"}
      candidate={candidate}
      resume={resume._id}
      query={{
        application: _id,
      }}
    >
      <Helmet title={"Resume"} />
      <Flex gap="3" direction={["column", null, "row"]}>
        <Stack flexBasis={[null, null, 64]} direction={["row", null, "column"]}>
          <ResumeThumbnail type="redacted" label={t("redacted")} />
          <ResumeThumbnail type="original" label={t("original")} />
        </Stack>

        <Box border="2px solid" w="full" minH="96">
          <ResumeViewer />
        </Box>
      </Flex>
    </ResumeViewerProvider>
  );
};
