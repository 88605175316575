import { Link } from "@chakra-ui/react";
import { toDateString } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import {
  DataTable,
  EmptyState,
  ResourceColumn,
  useDataTableHydrator,
} from "@cx/ui-data-table";
import {
  getRedactableName,
  JobLink,
  PopulatedJobApplication,
} from "@cx/ui-features";
import { FC, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

export const JobApplicationSummaryTable: FC = () => {
  const resource = "job-applications";
  const { data, invalidate, ...hydratorProps } = useDataTableHydrator(
    resource,
    {
      $populate: ["job"],
      $limit: 5,
    },
  );
  const { t } = useTranslation("applications");

  const columns = useMemo(
    (): ResourceColumn<PopulatedJobApplication[]> => [
      {
        Header: t("candidate"),
        id: "candidate",
        accessor({ _id, firstName, lastName }) {
          return { _id, firstName, lastName };
        },
        Cell({ value: { _id, firstName, lastName } }) {
          return (
            <Link as={RouterLink} to={`/job-applications/${_id}`}>
              {getRedactableName(firstName, lastName)}
            </Link>
          );
        },
        disableSortBy: true,
      },

      {
        Header: t("job"),
        accessor: "job",
        Cell: JobLink,
        disableSortBy: true,
      },

      {
        Header: t("createdAt"),
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return <>{toDateString(createdAt)}</>;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource="job applications" />}
        columns={columns}
        data={data as unknown as PopulatedJobApplication[]}
        initialState={{
          pageIndex: 0,
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        {...hydratorProps}
        isPaginated={false}
        showTotal={false}
      />
    </>
  );
};
