import { useTranslation } from "@cx/react-polyglot";
import { ApplicationTransitionButton } from "@cx/ui-features";
import { FC } from "react";
import { BiBlock } from "react-icons/bi";
import { useApplicantActionContext } from "./ApplicantActionContext";

export const RejectButton: FC = () => {
  const { t } = useTranslation("applications");
  const { fontSize, updateStatus } = useApplicantActionContext();

  return (
    <ApplicationTransitionButton
      icon={BiBlock}
      label={t("reject")}
      fontSize={fontSize}
      onClick={() => updateStatus("REJECT")}
      colorScheme="red"
    />
  );
};
