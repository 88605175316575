import { PageTitle } from "~/src/components/PageTitle";
import { UserForm } from "~/src/feature/User/UserForm";

export const Settings = () => {
  return (
    <>
      <PageTitle page="settings" type="view" />
      <UserForm />
    </>
  );
};
