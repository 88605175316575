import { JobWithCompany } from "@cx/feathers-client";
import { toDateString } from "@cx/format";
import { useTranslation } from "@cx/react-polyglot";
import { DateFilter } from "@cx/ui-common";
import {
  ActionPanelCell,
  DataTable,
  EmptyState,
  ResourceColumn,
  useActionPanel,
  useDataTableHydrator,
} from "@cx/ui-data-table";
import { FC, useMemo } from "react";

export const JobTable: FC = () => {
  const resource = "jobs";
  const { data, invalidate, ...hydratorProps } = useDataTableHydrator(resource);
  const { deleteEntry, Panel } = useActionPanel(resource, invalidate);
  const { t } = useTranslation("jobs");

  const columns = useMemo(
    (): ResourceColumn<typeof data> => [
      {
        Header: t("title"),
        accessor: "title",
      },
      {
        Header: t("location"),
        accessor: "location.formatted",
      },
      {
        Header: t("createdAt"),
        accessor: "createdAt",
        Cell({ value: createdAt }) {
          return <>{toDateString(createdAt)}</>;
        },
      },
      {
        Header: "",
        id: "button",
        accessor: "_id",
        Cell({ value: id }) {
          return <ActionPanelCell onClick={() => deleteEntry(id)} link={id} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <DataTable
        EmptyState={<EmptyState resource={resource} />}
        columns={columns}
        data={data}
        initialState={{
          pageIndex: 0,
          sortBy: [{ id: "createdAt", desc: true }],
        }}
        {...hydratorProps}
        isSearchable
      >
        {(instance) => <DateFilter instance={instance} />}
      </DataTable>
      {Panel}
    </>
  );
};
