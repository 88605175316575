import { FC } from "react";
import { PageTitle } from "~/src/components/PageTitle";
import { JobApplicationTable } from "~/src/feature/JobApplication";

export const ListJobApplications: FC = () => {
  return (
    <>
      <PageTitle page="jobApplications" type="list" />
      <JobApplicationTable />
    </>
  );
};
