import { ButtonGroup, Center } from "@chakra-ui/react";
import { FC } from "react";
import { useApplicantActionContext } from "./ApplicantActionContext";
import { RejectButton } from "./RejectButton";
import { ResetButton } from "./ResetButton";
import { RevealButton } from "./RevealButton";

export const ApplicantActionButtons: FC = () => {
  const { status } = useApplicantActionContext();

  if (status === "revealed") {
    return (
      <ButtonGroup>
        <RejectButton />
        <ResetButton />
      </ButtonGroup>
    );
  }

  if (status === "rejected") {
    return (
      <Center>
        <ResetButton />
      </Center>
    );
  }

  return (
    <ButtonGroup>
      <RejectButton />
      <RevealButton />
    </ButtonGroup>
  );
};
