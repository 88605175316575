import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import {
  Company,
  JobWithCompanyAndOwner,
  Recruiter,
} from "@cx/feathers-client";
import { useTranslation } from "@cx/react-polyglot";
import { JobForm } from "@cx/ui-features";
import { AsyncDropdown } from "@cx/ui-form";
import omit from "lodash.omit";
import { FC } from "react";

type Props = {
  job?: JobWithCompanyAndOwner;
  company: Company;
};

export const RecruiterJobForm: FC<Props> = ({ job, company }) => {
  const { t } = useTranslation("forms.job");

  let recruiter: Recruiter | undefined;

  if (job?.owner) {
    recruiter = {
      firstName: job.owner.firstName,
      lastName: job.owner.lastName,
    } as Recruiter;
  }

  return (
    <JobForm
      data={job ? omit(job, "owner") : undefined}
      company={company}
      components={{
        Company: (
          <FormControl isRequired isDisabled>
            <FormLabel>{t("company")}</FormLabel>
            <Input value={company.name} />
          </FormControl>
        ),
        Owner: (
          <AsyncDropdown
            key={`owner`}
            field="owner"
            title={t("owner.title")}
            service="recruiters"
            getLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
            getValue={(recruiter) => recruiter.user}
            defaultValue={recruiter}
            query={($search) => ({ $search })}
            helperText={t("owner.helperText")}
            required
          />
        ),
      }}
    />
  );
};
