import { NavbarItem } from "@cx/ui-common";
import { HiOutlineBriefcase } from "react-icons/hi";
import { IoBusinessOutline, IoFileTrayFullOutline } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { FiSettings } from "react-icons/fi";

export const navbarItems: NavbarItem[] = [
  { name: "nav.dashboard", path: "/dashboard", icon: MdOutlineDashboard },
  { name: "nav.company", path: "/company", icon: IoBusinessOutline },
  { name: "nav.jobs", path: "/jobs", icon: HiOutlineBriefcase },
  {
    name: "nav.jobApplications",
    path: "/job-applications",
    icon: IoFileTrayFullOutline,
  },
  { name: "nav.settings", path: "/settings", icon: FiSettings },
];
