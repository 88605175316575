import { Button } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { FullPageError } from "@cx/ui-common";
import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";

export const NotFound: FC = () => {
  const { t } = useTranslation("errors.notFound");

  return (
    <>
      <Helmet title={t("pageTitle")} />

      <FullPageError
        code="404"
        description={t("description")}
        heading={t("heading")}
      >
        <Button as={NavLink} to="/" colorScheme="gray">
          {t("goHome")}
        </Button>
      </FullPageError>
    </>
  );
};
