import { Center, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "@cx/react-polyglot";
import { ResendVerificationLink, useVerifyEmail } from "@cx/ui-features";
import { FC } from "react";
import { Helmet } from "react-helmet-async";

export const VerifyEmail: FC = () => {
  const { t } = useTranslation("auth.verification");
  const { message, loading, error, payload } = useVerifyEmail("/");

  return (
    <>
      <Helmet title={t("pageTitle")} />

      <Text variant="cx-title">{t("verifying")}</Text>
      <Center pt="10" flexDirection="column">
        {loading && <Spinner label={t("verifying")} size="lg" />}
        <Text>{message}</Text>
      </Center>

      {error && payload?.email && (
        <ResendVerificationLink email={payload.email} />
      )}
    </>
  );
};
