import { RouteFactory } from "@cx/ui-common";
import { Route } from "react-router-dom";
import { JobApplicationViewLayout } from "~/src/feature/JobApplication";
import { ListJobApplications } from "./ListJobApplications";
import { ViewJobApplication } from "./ViewJobApplication";
import { ViewJobApplicationResume } from "./ViewJobApplicationResume";

export const JobApplicationRoutes = (
  <>
    {RouteFactory("job-applications", ListJobApplications, null, null)}

    <Route path="job-applications/:id" element={<JobApplicationViewLayout />}>
      <Route index element={<ViewJobApplication />} />
      <Route path="resume" element={<ViewJobApplicationResume />} />
    </Route>
  </>
);
