import { useTranslation } from "@cx/react-polyglot";
import { FC } from "react";
import { JobSummaryTable } from "../Job/JobSummaryTable";
import { SummaryCard } from "@cx/ui-common";

export const JobSummary: FC = () => {
  const { t } = useTranslation("dashboard");

  return (
    <SummaryCard label={t("latest.jobs")}>
      <JobSummaryTable />
    </SummaryCard>
  );
};
