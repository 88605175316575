import { useTranslation } from "@cx/react-polyglot";
import { SetPasswordForm } from "@cx/ui-features";
import { FC } from "react";
import { HeaderInfo } from "~/src/feature/HeaderInfo";

export const SetPassword: FC = () => {
  const { t } = useTranslation("auth.setPassword");
  return (
    <>
      <HeaderInfo heading={t("heading")} pageTitle={t("pageTitle")} />
      <SetPasswordForm redirect="/dashboard" />
    </>
  );
};
