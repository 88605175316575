import { FC } from "react";
import { useSelector } from "react-redux";
import { PageTitle } from "~/src/components/PageTitle";
import { RecruiterJobForm } from "~/src/feature/Job/RecruiterJobForm";
import { selectRecruiterCompany } from "~/src/state/slices/recruiter-slice";

export const CreateJob: FC = () => {
  const company = useSelector(selectRecruiterCompany);

  return (
    <>
      <PageTitle page="jobs" type="create" />
      <RecruiterJobForm company={company} />
    </>
  );
};
