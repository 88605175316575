import { authReducer, opts, rootReducerFactory } from "@cx/ui-state";
import { combineReducers } from "redux";
import { app } from "~/lib/feathers";
import { recruiterReducer } from "./recruiter-slice";

const combinedReducers = combineReducers({
  auth: authReducer,
  recruiter: recruiterReducer,
});

type _RootState = ReturnType<typeof combinedReducers>;

export const rootReducer = rootReducerFactory(combinedReducers);

opts.set("app", app);

declare module "@cx/ui-state" {
  export interface RootState extends _RootState {}
}
