import { feathersClient, handleUnauthenticated } from "@cx/feathers-client";
import { getRegion } from "@cx/region-config";

const { DEV, VITE_REGION, VITE_API } = globalThis.config;

export const app = feathersClient(VITE_API, {
  headers: {
    "x-region": getRegion(VITE_REGION, DEV),
    "x-role": "recruiter",
  },
});

app.hooks({
  error: {
    all: [handleUnauthenticated(401, 403)],
  },
});
