import { Center, Container, Spinner } from "@chakra-ui/react";
import { waitAtLeast } from "@cx/sleep";
import {
  FullPageError,
  ToggleButton,
  VerticalNavBar,
  useVerticalNavbar,
} from "@cx/ui-common";
import { ErrorBoundedOutlet, InvalidRegion } from "@cx/ui-features";
import { useOnClear, useRouteGuard } from "@cx/ui-hooks";
import { selectIsAuthenticated, useAppDispatch } from "@cx/ui-state";
import { FeathersError } from "@feathersjs/errors";
import { FC } from "react";
import { useAsync } from "react-use";
import { fetchRecruiter } from "~/src/state/slices/recruiter-slice";
import { useNavbarItems } from "../Navigation";

const LoadingView: FC = () => {
  return (
    <Center minH="100vh" width="full" position="absolute" top={0} left={0}>
      <Spinner label="loading..." size="xl" />
    </Center>
  );
};

export const DefaultLayout: FC = () => {
  const isAllowed = useRouteGuard("/", selectIsAuthenticated);

  useOnClear();
  const { colorToggleText, expanded, getButtonProps, transitionProps, width } =
    useVerticalNavbar("Light", "Dark");
  const navItems = useNavbarItems();

  const dispatch = useAppDispatch();
  const { loading, error } = useAsync(
    async () => await waitAtLeast(() => dispatch(fetchRecruiter()).unwrap()),
    [],
  );

  if (!isAllowed) {
    return null;
  }

  if (loading) {
    return <LoadingView />;
  }

  if (error?.message.startsWith("Invalid region")) {
    return <InvalidRegion error={error as any} />;
  }

  if (error) {
    console.error(error);
    return (
      <FullPageError
        code={(error as FeathersError).code}
        description={error.message}
        heading={error.name}
      />
    );
  }

  return (
    <>
      <VerticalNavBar
        labels={{
          signOut: "Sign Out",
          colorToggle: colorToggleText,
        }}
        expanded={expanded}
        navItems={navItems}
      >
        <ToggleButton expanded={expanded} {...getButtonProps()} />
      </VerticalNavBar>

      <Container maxW="95%" mt="20" mb="6" pl={width} {...transitionProps}>
        <ErrorBoundedOutlet />
      </Container>
    </>
  );
};
