import { useTranslation } from "@cx/react-polyglot";
import { LoginForm, MirrorLayout } from "@cx/ui-features";
import { FC } from "react";
import { HeaderInfo } from "~/src/feature/HeaderInfo";

export const Login: FC = () => {
  const { t } = useTranslation("auth.login");

  return (
    <MirrorLayout
      logoAria={t("aria.logo")}
      bgAria={t("aria.bg")}
      company={t("cx")}
      reverse
    >
      <HeaderInfo heading={t("heading")} pageTitle={t("pageTitle")} />
      <LoginForm forgot="/reset-password" redirect="/dashboard" />
    </MirrorLayout>
  );
};
