import { InferType, mixed, object, string } from "yup";

export const UserSchema = object({
  firstName: string().required().label("settings.firstName"),
  lastName: string().required().label("settings.lastName"),
  email: string().required().label("settings.email"),
  password: mixed<string>()
    .transform((value) => value || undefined)
    .label("settings.password"),
});

export type UserSchema = InferType<typeof UserSchema>;
